import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
	ListItem,
	ListItemText,
	Button,
	Snackbar,
} from "@mui/material";
// cmp
import Code from "../code";

type Props = {
	label: string;
	value: any;
};

const LogDetailItem = ({ label, value }: Props) => {
	const { t } = useTranslation();
	const [showCopySnackbar, setShowCopySnackbar] = useState(false);

	const isObject = (value !== null && typeof value === "object");

	const getValue = (value) => {
		if (value instanceof Date) {
			return value.toLocaleString();
		}
		if (isObject) {
			try {
				const jsonString = JSON.stringify(value, null, 2);

				const handleCopyClick = (value: string): void => {
					navigator.clipboard.writeText(value).then(() => {
						setShowCopySnackbar(true);
					}, (error) => {
						console.warn(error);
					});
				};

				return (
					<>
						<Button className="btn-log-value-copy" variant="contained" onClick={() => (handleCopyClick(jsonString))}>{t("generic.copy")}</Button>
						<Code code={jsonString} />
						<Snackbar
							open={showCopySnackbar}
							message="JSON copied"
							autoHideDuration={3000}
							onClose={() => (setShowCopySnackbar(false))}
						/>
					</>
				);
			} catch (e) {
				return "Error converting object to string";
			}
		}
		return String(value);
	};

	return (
		<ListItem dense={true}>
			<ListItemText
				primary={label}
				secondary={getValue(value)}
				secondaryTypographyProps={isObject ? {component: "div"} : {}}
			/>
		</ListItem>
	);
};

export default LogDetailItem;
