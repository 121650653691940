import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
	Table,
	TableBody,
	TableHead,
	TableCell,
	IconButton,
	TableRow,
	ListItemIcon,
	Typography,
	Link,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
// cmp
import withNavigateAndParams from "../withNavigateAndParams";
import Na from "../na";
import Svg from "../svg";
// services
import { icons } from "@local/theme";

const TableEditorAdvanced = (props) => {
	const theme = useTheme();
	const { t } = useTranslation();

	const columnStyle = {
		paddingRight: "5px",
		paddingLeft: "30px",
		whiteSpace: "normal",
		overflow: "inherit",
		wordBreak: "break-word",
	};

	const headingStyle = {
		paddingRight: "5px",
		paddingLeft: "30px",
	};

	const handleTableItemClick = (tableItem) => {
		props.navigate(`/teditor/customEditor/${tableItem.id.string_key}`);
	};

	const handleDuplicateClick = (event, tableItem) => {
		event.stopPropagation();
		props.onDuplicateClickHandler(true, tableItem);
	};

	const handleDeleteClick = (event, tableItem) => {
		event.stopPropagation();
		props.onDeleteClick(tableItem);
	};

	return (
		<Table>
			<TableHead>
				<TableRow>
					<TableCell style={headingStyle}>{t("teditor.key")}</TableCell>
					<TableCell style={headingStyle}>{t("teditor.name")}</TableCell>
					<TableCell style={headingStyle}>{t("teditor.type")}</TableCell>
					<TableCell style={headingStyle}>{t("teditor.category")}</TableCell>
					<TableCell style={headingStyle}>{t("teditor.help")}</TableCell>
					<TableCell style={headingStyle} />
				</TableRow>
			</TableHead>
			<TableBody>
				{(props.filteredData.length === 0) ?
					<TableRow className="last-row-no-border" style={{ height: "62px" }}>
						<TableCell colSpan={7} style={columnStyle} padding="none">
							<Typography variant="h5" style={{ fontSize: "1.1rem" }}>
								{t("teditor.noRecords")}
							</Typography>
						</TableCell>
					</TableRow>
					:
					props.filteredData.map((tableItem) => (
						<TableRow
							key={tableItem.id.string_key}
							hover={true}
							onClick={() => (handleTableItemClick(tableItem))}
							className="last-row-no-border"
							style={{ height: "62px", cursor: "pointer" }}
						>
							<TableCell style={columnStyle} padding="none">{tableItem.id.string_key || <Na />}</TableCell>
							<TableCell style={columnStyle} padding="none">
								<div>{tableItem.data.mfg_name || <Na />}</div>
								<div style={{ color: theme.palette.text.secondary, fontSize: "11px" }}>
									{tableItem.data.mfg_model || ""}
								</div>
							</TableCell>
							<TableCell style={columnStyle} padding="none">
								<div>{t(`rocIds.${tableItem.data.type}`) || <Na />}</div>
								<div style={{ color: theme.palette.text.secondary, fontSize: "11px" }}>
									{tableItem.data.type || ""}
								</div>
							</TableCell>
							<TableCell style={columnStyle} padding="none">
								<div>{t(`deviceCategories.${tableItem.data.category}`) || <Na />}</div>
								<div style={{ color: theme.palette.text.secondary, fontSize: "11px" }}>
									{tableItem.data.category || ""}
								</div>
							</TableCell>
							<TableCell style={columnStyle} padding="none">
								{tableItem.data.mfg_help &&
									<ListItemIcon>
										<IconButton component={Link} href={tableItem.data.mfg_help} target="_blank" onClick={(event) => (event.stopPropagation())}>
											<Svg src="navigation/openNewWindow.svg" color={theme.palette.primary.main} />
										</IconButton>
									</ListItemIcon>
								}
							</TableCell>
							<TableCell style={{ ...columnStyle, textAlign: "right" }} padding="none">
								<ListItemIcon>
									<IconButton
										title={t("teditor.duplicate")}
										disabled={!props.hasWriteAccess}
										onClick={(event) => (handleDuplicateClick(event, tableItem))}
									>
										<icons.CopyContent htmlColor={props.hasWriteAccess ? theme.palette.primary.main : theme.palette.text.secondary} />
									</IconButton>
								</ListItemIcon>
								<ListItemIcon>
									<IconButton
										title={t("teditor.remove")}
										disabled={!props.hasWriteAccess}
										onClick={(event) => (handleDeleteClick(event, tableItem))}
									>
										<Svg src="actions/delete.svg" color={props.hasWriteAccess ? theme.palette.primary.main : theme.palette.text.secondary} />
									</IconButton>
								</ListItemIcon>
							</TableCell>
						</TableRow>
					))
				}
			</TableBody>
		</Table>
	);
};

TableEditorAdvanced.propTypes = {
	hasWriteAccess: PropTypes.bool.isRequired,
	filteredData: PropTypes.arrayOf(PropTypes.object).isRequired,
	onDuplicateClickHandler: PropTypes.func.isRequired,
	onDeleteClick: PropTypes.func.isRequired,
	navigate: PropTypes.func.isRequired,
};

export default withNavigateAndParams(TableEditorAdvanced);
