import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Paper, ToggleButtonGroup, ToggleButton } from "@mui/material";
// hooks
import useStorage, { StorageKeys } from "../hooks/useStorage";
// services
import { config } from "@local/theme";
// types
import type { MouseEvent } from "react";
import type { ServerId } from "../types/config";

const ServerOptions = () => {
	const { t } = useTranslation();

	const [selectedBackendServer, setSelectedBackendServer] = useStorage(StorageKeys.selectedBackendServer);

	const handleChange = useCallback((_event: MouseEvent<HTMLElement>, value: ServerId | null) => {
		if (value !== null) {
			setSelectedBackendServer(value);
		}
	}, []);

	return (
		<Paper variant="outlined">
			<ToggleButtonGroup
				fullWidth={true}
				color="primary"
				exclusive={true}
				value={selectedBackendServer}
				onChange={handleChange}
				aria-label={t("settings.serverOptions")}
			>
				{config.serverOptions.map((serverOption) => (
					<ToggleButton key={serverOption.id} value={serverOption.id}>
						{serverOption.label}
					</ToggleButton>
				))}
			</ToggleButtonGroup>
		</Paper>
	);
};

export default ServerOptions;
