import { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import {
	Card,
	CardHeader,
	CardContent,
	CircularProgress,
	Checkbox,
	ListItem,
	ListItemText,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Table,
	TableBody,
	TableRow,
	TableCell,
} from "@mui/material";
// services
import Gupport from "../../services/gupport";

class ExportBackup extends Component {

	constructor(props) {
		super(props);

		this.state = {
			// ready: Gupport.ready,
			loading: true,
			error: null,
			payload: null,
			selectedTables: [],
			openErrorDialog: false,
			openSuccessDialog: false,
			backupUrl: null,
		};

		// this.handleGupportReady = this.handleGupportReady.bind(this);
		this.saveTables = this.saveTables.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleRowSelected = this.handleRowSelected.bind(this);
	}

	componentDidMount() {
		// Gupport.on("ready", this.handleGupportReady);

		this.fetchBackupTables();
	}

	fetchBackupTables() {
		const cmd = {
			action: "getBackupTables",
		};
		Gupport.send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				this.setState({
					loading: false,
					error: null,
					payload: msg.payload,
					selectedTables: [],
					backupUrl: null,
				});
			} else {
				this.setState({
					loading: false,
					error: msg.payload.data,
					payload: null,
					selectedTables: [],
					backupUrl: null,
				});
			}
		});
	}

	// componentWillUnmount() {
	// 	Gupport.off("ready", this.handleGupportReady);
	// }

	// handleGupportReady() {
	// 	this.setState({
	// 		ready: Gupport.ready
	// 	});
	// }

	saveTables() {
		const cmd = {
			action: "getBackup",
			tables: this.state.selectedTables,
		};
		Gupport.send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				this.setState({
					openSuccessDialog: true,
					backupUrl: msg.payload.data.metaUrl,
				});
			} else {
				this.setState({
					openErrorDialog: true,
					backupUrl: null,
				});
			}
		});
	}

	handleClose() {
		this.setState({
			openSuccessDialog: false,
			openErrorDialog: false,
		});
	}

	handleRowSelected(value, event, isInputChecked) {
		this.setState((prevState) => {
			const selectedTables = prevState.selectedTables.slice();
			if (isInputChecked) {
				selectedTables.push(value);
			} else {
				selectedTables.splice(selectedTables.indexOf(value), 1);
			}
			return {
				selectedTables: selectedTables,
			};
		});
	}

	render() {
		const { t } = this.props;

		if (this.state.loading) {
			return <CircularProgress style={{ margin: "auto", display: "block" }} />;
		}
		if (this.state.error) {
			return <div>{t("generic.errorWithMsg", { msg: "" })}<pre>{this.state.error.message}</pre></div>;
		}
		if (!this.state.payload) {
			return null;
		}

		return (
			<div style={{ maxWidth: "700px", margin: "0 auto" }}>
				{
					this.state.payload.dicts.map((category, dictIndex) => (
						<Card key={dictIndex} style={{ marginTop: "10px", overflow: "hidden" }}>
							<CardHeader
								title={`${category.desc} (${category.id})`}
								actAsExpander={true}
								showExpandableButton={false}
							/>
							<CardContent expandable={false}>
								<Table>
									<TableBody>
										{category.tables.map((table, tableIndex) => (
											<TableRow key={tableIndex} className="last-row-no-border">
												<TableCell style={{ width: "72px" }}>
													<Checkbox
														checked={this.state.selectedTables.includes(table)}
														onChange={this.handleRowSelected.bind(this, table.name)}
													/>
												</TableCell>
												<TableCell>
													<ListItem disabled={true}>
														<ListItemText primary={table.name} secondary={`Count: ${table.count}, Size: ${table.size}`} />
													</ListItem>
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</CardContent>
						</Card>
					))
				}
				<div style={{ marginTop: "24px" }}>
					<Button
						variant="contained"
						disabled={this.state.selectedTables.length === 0}
						style={{ marginBottom: "24px", float: "right" }}
						onClick={this.saveTables}
					>
						{t("backup.exportBtn")}
					</Button>
				</div>
				<Dialog
					fullWidth={true}
					open={this.state.openErrorDialog}
					onClose={this.handleClose}
				>
					<DialogTitle>{t("backup.exportErrorTitle")}</DialogTitle>
					<DialogContent>
						<DialogContentText>
							{t("backup.exportErrorMsg")}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleClose}>{t("dialog.ok")}</Button>
					</DialogActions>
				</Dialog>
				<Dialog
					fullWidth={true}
					open={this.state.openSuccessDialog}
					onClose={this.handleClose}
				>
					<DialogTitle>{t("backup.exportSuccessTitle")}</DialogTitle>
					<DialogContent>
						<DialogContentText>
							{t(`backup.exportSuccessMsg\n${this.state.backupUrl}`)}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleClose}>{t("dialog.ok")}</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}

}

ExportBackup.propTypes = {
	t: PropTypes.func.isRequired,
};

export default withTranslation()(ExportBackup);
