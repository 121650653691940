import { memo, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { TextField, Checkbox, FormControlLabel, Typography } from "@mui/material";

const TableEditorFilter = (props) => {
	const { t } = useTranslation();
	const [query, setQuery] = useState(props.filterValue);

	const timeoutIdRef = useRef(undefined);

	useEffect(() => (
		() => {
			window.clearTimeout(timeoutIdRef.current);
		}
	), []);

	useEffect(() => {
		setQuery(props.filterValue);
	}, [props.filterValue]);

	useEffect(() => {
		window.clearTimeout(timeoutIdRef.current);

		timeoutIdRef.current = window.setTimeout(() => {
			props.onFilterChange(query);
		}, 300);
	}, [query]);

	return (
		<>
			<div style={{ display: "flex", flexDirection: props.isRocidDict ? "row" : "column", alignItems: "center", margin: "16px 16px 4px" }}>
				<TextField
					placeholder={t("teditor.search")}
					fullWidth={true}
					autoFocus={true}
					value={query}
					onChange={(event) => (setQuery(event.target.value))}
					style={{ margin: "0 10px" }}
				/>
				<div style={{display: "flex"}}>
					<FormControlLabel
						label={t("teditor.searchAll")}
						control={<Checkbox checked={props.searchAll} onChange={props.onSearchInDataToggle} style={{ marginLeft: "16px" }} />}
						style={{ whiteSpace: "nowrap" }}
					/>
					<FormControlLabel
						label={t("teditor.searchByRegex")}
						control={<Checkbox checked={props.searchByRegex} onChange={props.onSearchByRegexToggle} />}
						style={{ whiteSpace: "nowrap" }}
					/>
				</div>
			</div>
			{(props.searchResultLength === 0) ?
				<Typography variant="subtitle2" style={{margin: "8px 16px"}}>
					{props.isRocidDict ? null : t("teditor.noRecords")}
				</Typography>
				:
				<Typography variant="body2" color="textSecondary" style={props.isRocidDict ? {margin: "8px 26px"} : {margin: "8px 16px"}}>
					{(props.searchResultLength === 1) ? t("teditor.oneEntryFound") : t("teditor.entriesFound", { COUNT: props.searchResultLength })}
				</Typography>
			}
		</>
	);
};

TableEditorFilter.defaultProps = {
	filterValue: "",
};

TableEditorFilter.propTypes = {
	isRocidDict: PropTypes.bool.isRequired,
	filterValue: PropTypes.string,
	searchAll: PropTypes.bool.isRequired,
	searchByRegex: PropTypes.bool.isRequired,
	onFilterChange: PropTypes.func.isRequired,
	onSearchByRegexToggle: PropTypes.func.isRequired,
	onSearchInDataToggle: PropTypes.func.isRequired,
	searchResultLength: PropTypes.number.isRequired,
};

export default memo(TableEditorFilter);
