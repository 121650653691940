import { createHashRouter, Navigate } from "react-router-dom";
import RootRenderElement from "./RootRenderElement";
import RouteListener from "./RouteListener";
// pages
import WelcomePage from "./scripts/pages/welcome-page";
import GatewaysPage from "./scripts/pages/gateways-page";
import UsersPage from "./scripts/pages/users-page";
import StatsGerverPage from "./scripts/pages/stats-gerver-page";
import GLogPage from "./scripts/pages/glog-page";
// import GlientPlaygroundPage from "./scripts/pages/glient-playground-page";
import GupportPlaygroundPage from "./scripts/pages/gupport-playground-page";
import SupportUsersPage from "./scripts/pages/support-users-page";
import TEditorPage from "./scripts/pages/teditor-page";
import CustomTEditorPage from "./scripts/pages/custom-teditor-page";
import BackupPage from "./scripts/pages/backup-page";
import AboutPage from "./scripts/pages/about-page";
import LogPage from "./scripts/pages/log-page";
import SettingsPage from "./scripts/pages/settings-page";
import LocalizationPage from "./scripts/pages/localization-page";
import LoginPage from "./scripts/pages/login-page";
import AccountAddPage from "./scripts/pages/account-add-page";
import GeneralSettingsPage from "./scripts/pages/general-settings-page";
// types
import type { RouteObject } from "react-router-dom";

const IndexRouter = [
	{
		path: "/",
		element: <RootRenderElement to="/gateways" />,
	},
	{
		path: "/login",
		element: <LoginPage />,
	},
	{
		path: "/accounts/add/:accountFor",
		element: <AccountAddPage />,
	},
	{
		path: "/*",
		element: <RouteListener />,
		children: [
			{
				path: "welcome",
				element: <WelcomePage />,
			},
			{
				path: "gateways",
				children: [
					{
						index: true,
						element: <GatewaysPage />,
					},
					{
						path: ":gatewayId",
						element: <Navigate replace={true} to="general" />,
					},
					{
						path: ":gatewayId/:tab",
						element: <GatewaysPage />,
					},
				],
			},
			{
				path: "users",
				children: [
					{
						index: true,
						element: <UsersPage />,
					},
					{
						path: ":userId",
						element: <Navigate replace={true} to="general" />,
					},
					{
						path: ":userId/:tab",
						element: <UsersPage />,
					},
				],
			},
			{
				path: "stats/gerver",
				element: <StatsGerverPage />,
			},
			{
				path: "glogs",
				element: <GLogPage />,
			},
			// {
			// 	path: "playground/glient",
			// 	element: <GlientPlaygroundPage />,
			// },
			{
				path: "playground/gupport",
				element: <GupportPlaygroundPage />,
			},
			{
				path: "support/users",
				children: [
					{
						index: true,
						element: <SupportUsersPage />,
					},
					{
						path: ":userId",
						element: <SupportUsersPage />,
					},
				],
			},
			{
				path: "teditor",
				children: [
					{
						index: true,
						element: <TEditorPage />,
					},
					{
						path: ":tableId",
						element: <TEditorPage />,
					},
					{
						path: "customEditor/:stringKey",
						element: <CustomTEditorPage />,
					},
				],
			},
			{
				path: "backups",
				element: <BackupPage />,
			},
			{
				path: "utilities/localization",
				element: <LocalizationPage />,
			},
			{
				path: "about",
				element: <AboutPage />,
			},
			{
				path: "general-settings",
				element: <GeneralSettingsPage />,
			},
			{
				path: "logs",
				element: <LogPage />,
			},
			{
				path: "settings",
				element: <SettingsPage />,
			},
		],
	},
	{
		path: "*",
		element: <Navigate replace={true} to="/" />,
	},
] satisfies Array<RouteObject>;

export default createHashRouter(IndexRouter);
