import { useState, useEffect, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
	Button,
	Paper,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	List,
} from "@mui/material";
import { GridActionsCellItem, DataGrid } from "@mui/x-data-grid";
// cmp
import LogDetailItem from "./LogDetailItem";
// services
import Gupport from "../../services/gupport";
import { icons } from "@local/theme";
// types
import type { GridRowId, GridColDef } from "@mui/x-data-grid";
import type { CmdGetLogs } from "../../types/gupport";

export interface Log {
	_timestamp: string;
	action: "gatewayDisconnect" | "login";
	activeDBnodes: number;
	agent?: string;
	apps: number;
	channel?: string;
	connected: string;
	gatewayId: string;
	gateways: number;
	handler: string;
	hook_ts: string;
	id: string;
	info?: string;
	ip: string;
	level: string;
	logText: string;
	node: string;
	rxCount: number;
	rxSize: number;
	socket_status: "closed" | "live";
	supUserId: string;
	txCount: number;
	txSize: number;
	users: number;
	branch?: string;
	buildDate?: string;
	buildId?: string;
}

const GLogTable = () => {
	const { t } = useTranslation();

	const [loading, setLoading] = useState(true);
	const [showLogDialog, setShowLogDialog] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [logs, setLogs] = useState<Array<Log>>([]);
	const [logData, setLogData] = useState<Log | undefined>(undefined);

	const handleShowJsonClick = useCallback((id: GridRowId) => (
		() => {
			setLogData(logs.find((log) => (log.id === id)));
			setShowLogDialog(true);
		}
	), [logs]);

	const columns = useMemo(() => ([
		{
			field: "logText",
			headerName: t("glogs.text"),
			flex: 6,
		},
		{
			field: "_timestamp",
			headerName: t("glogs.timestamp"),
			flex: 1,
			renderCell: (params) => (<time dateTime={new Date(params.value).toISOString()}>{new Date(params.value).toLocaleString()}</time>),
			valueFormatter: (value) => (new Date(value).toISOString()),
		},
		{
			field: "level",
			headerName: t("glogs.level"),
			flex: 1,
		},
		{
			field: "actions",
			headerName: "Raw",
			type: "actions",
			flex: 1,
			// eslint-disable-next-line react/no-unstable-nested-components
			getActions: (params) => ([
				<GridActionsCellItem
					key={params.id}
					label="Raw"
					icon={<icons.RawOn />}
					onClick={handleShowJsonClick(params.id)}
				/>,
			]),
		},
	] as const satisfies ReadonlyArray<GridColDef<Log>>), [t, handleShowJsonClick]);

	const fetchLogs = () => {
		if (Gupport.getLogs) {
			const cmd = {
				action: "getLogs",
			} as const satisfies CmdGetLogs;
			Gupport.send(cmd, (error, msg) => {
				if (!error && msg?.payload.status === "ok") {
					setError(null);
					setLogs(msg.payload.data);
				} else {
					setError(t("glogs.msg"));
					setLogs([]);
				}
				setLoading(false);
			});
		}
	};

	useEffect(() => {
		if (Gupport.ready) {
			fetchLogs();
		} else {
			Gupport.once("ready", () => {
				fetchLogs();
			});
		}
	}, []);

	if (error) {
		return <div>{error}</div>;
	}

	return (
		<>
			<Paper>
				<DataGrid
					loading={loading}
					columns={columns}
					rows={logs}
					sx={{
						"& .MuiDataGrid-actionsCell svg": {
							width: "26px",
							height: "26px",
						},
					}}
				/>
			</Paper>
			<Dialog
				id="dlg-log-details"
				fullWidth={true}
				open={showLogDialog}
				onClose={() => (setShowLogDialog(false))}
			>
				<DialogTitle>{t("glogs.dialogTitle")}</DialogTitle>
				<DialogContent>
					<List disablePadding={true}>
						<LogDetailItem label={t("glogs.channel")} value={logData?.channel} />
						<LogDetailItem label={t("glogs.connected")} value={logData?.connected} />
						<LogDetailItem label={t("glogs.handler")} value={logData?.handler} />
						<LogDetailItem label={t("glogs.ip")} value={logData?.ip} />
						<LogDetailItem label={t("glogs.node")} value={logData?.node} />
						<LogDetailItem label={t("glogs.payload")} value={logData} />
					</List>
				</DialogContent>
				<DialogActions>
					<Button color="inherit" className="btn-dlg-action-close" onClick={() => (setShowLogDialog(false))}>{t("dialog.close")}</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default GLogTable;
