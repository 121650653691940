import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
	Divider,
	Collapse,
	List,
	ListItemButton,
	ListItemText,
	Avatar,
	Typography,
	ListItemIcon,
	TextField,
	Tooltip,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
// cmp
import RowEntry from "../../../row-entry";
import Svg from "../../../svg";
// services
import { icons } from "@local/theme";

type Help = {
	removing_help?: string;
	wakeup_help?: string;
};

type Props = {
	selectedItem?: Help;
	previousItem: Help;
	fieldUpdateCount: number;
	fieldUpdate: (event: any) => void;
};

const DEFAULT_SELECTED_ITEM = {
	removing_help: "",
	wakeup_help: "",
} as const satisfies Help;

const HelpsSection = ({ selectedItem = DEFAULT_SELECTED_ITEM, previousItem, fieldUpdateCount, fieldUpdate }: Props) => {
	const theme = useTheme();
	const { t } = useTranslation();

	const [expanded, setExpanded] = useState(false);

	return (
		<List disablePadding={true}>
			<ListItemButton
				onClick={() => (setExpanded(!expanded))}
				style={{ paddingTop: "12px", paddingBottom: "12px" }}
			>
				<ListItemText
					primary={
						<Typography variant="body2" style={{ fontWeight: "bold" }}>
							{t("customtEditor.sections.helps")}
						</Typography>
					}
				/>
				<Avatar className="count-badge">{fieldUpdateCount}</Avatar>
				<ListItemIcon>
					{expanded
						? <icons.ExpandLess htmlColor={theme.palette.text.secondary} />
						: <Svg src="navigation/arrowDropDown.svg" color={theme.palette.text.secondary} />
					}
				</ListItemIcon>
			</ListItemButton>
			<Divider />
			<Collapse in={expanded} timeout="auto">
				<RowEntry title={t("customtEditor.removing_help.text")} subTitle={t("customtEditor.removing_help.id")} styleRight={{ margin: "-8px 0", display: "flex", justifyContent: "flex-end" }}>
					{previousItem.removing_help !== selectedItem.removing_help &&
						<ListItemIcon style={{ alignItems: "center" }}>
							<Tooltip title={previousItem.removing_help ?? "NA"}>
								<Svg src="actions/editLine.svg" color={theme.palette.primary.main} />
							</Tooltip>
						</ListItemIcon>
					}
					<TextField
						fullWidth={true}
						name="removing_help"
						value={selectedItem.removing_help ?? ""}
						onChange={fieldUpdate}
						style={{ width: "calc(100% - 56px)" }}
					/>
				</RowEntry>
				<Divider />
				<RowEntry title={t("customtEditor.wakeup_help.text")} subTitle={t("customtEditor.wakeup_help.id")} styleRight={{ margin: "-8px 0", display: "flex", justifyContent: "flex-end" }}>
					{previousItem.wakeup_help !== selectedItem.wakeup_help &&
						<ListItemIcon style={{ alignItems: "center" }}>
							<Tooltip title={previousItem.wakeup_help ?? "NA"}>
								<Svg src="actions/editLine.svg" color={theme.palette.primary.main} />
							</Tooltip>
						</ListItemIcon>
					}
					<TextField
						fullWidth={true}
						name="wakeup_help"
						value={selectedItem.wakeup_help ?? ""}
						onChange={fieldUpdate}
						style={{ width: "calc(100% - 56px)" }}
					/>
				</RowEntry>
				<Divider />
			</Collapse>
		</List>
	);
};

export default HelpsSection;
