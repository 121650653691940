import { useState, useEffect, memo, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
	Paper,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	List,
} from "@mui/material";
import { GridActionsCellItem, DataGrid } from "@mui/x-data-grid";
import { lighten } from "@mui/material/styles";
// cmp
import LogDetailItem from "./LogDetailItem";
import Svg from "../svg";
// services
import Log from "../../services/log";
import { icons } from "@local/theme";
// types
import type { GridRowId, GridColDef } from "@mui/x-data-grid";
import type { WsType } from "../../types/roc-ws";
import type { Logs, Log as LogT } from "../../types/log";

type Props = {
	logType: WsType;
};

const LogsTabsContent = ({ logType }: Props) => {
	const { t } = useTranslation();

	const [logs, setLogs] = useState<Logs>(Log.getLogsOfType(logType));
	const [selectedLog, setSelectedLog] = useState<LogT | undefined>(undefined);

	const handleLogClicked = useCallback((id: GridRowId) => (
		() => {
			setSelectedLog(logs.find((log) => (log.id === id)));
		}
	), [logs]);

	const columns = useMemo(() => ([
		{
			field: "date",
			headerName: "Date",
			flex: 1,
			type: "dateTime",
			renderCell: (params) => (<time dateTime={params.value.toISOString()}>{params.value.toLocaleTimeString()}</time>),
		},
		{
			field: "level",
			headerName: "Level",
			flex: 1,
		},
		{
			field: "dir",
			headerName: "Direction",
			flex: 1,
			type: "singleSelect",
			valueOptions: ["TX", "RX", "other"],
			valueGetter: (value) => (value ?? "other"),
			renderCell: (params) => {
				if (params.value === "TX") {
					return <Svg src="navigation/arrowRight.svg" color="#00aa00" title="TX" />;
				}
				if (params.value === "RX") {
					return <Svg src="navigation/arrowLeft.svg" color="#dd0000" title="RX" />;
				}
				return "";
			},
		},
		{
			field: "message",
			headerName: "Message",
			flex: 7,
		},
		{
			field: "actions",
			headerName: "Raw",
			type: "actions",
			flex: 1,
			// eslint-disable-next-line react/no-unstable-nested-components
			getActions: (params) => ([
				<GridActionsCellItem
					key={params.id}
					label="Raw"
					icon={<icons.RawOn />}
					onClick={handleLogClicked(params.id)}
				/>,
			]),
		},
	] as const satisfies ReadonlyArray<GridColDef<LogT>>), [handleLogClicked]);

	const handleLogsChanged = (type: WsType/*, log: LogT*/) => {
		if (type === logType) {
			setLogs(Log.getLogsOfType(logType));
		}
	};

	useEffect(() => {
		Log.on("changed", handleLogsChanged);

		return () => {
			Log.off("changed", handleLogsChanged);
		};
	}, []);

	const handleChangeToMatchClick = (selectedLog: LogT) => {
		let matchLog: LogT | undefined = undefined;
		if (selectedLog.dir === "RX") {
			matchLog = Log.getLogsOfType(logType).find((log) => (log.dir === "TX" && log.payload.requestId === selectedLog.payload.responseId));
		}
		if (selectedLog.dir === "TX") {
			matchLog = Log.getLogsOfType(logType).find((log) => (log.dir === "RX" && log.payload.responseId === selectedLog.payload.requestId));
		}
		if (matchLog !== undefined) {
			setSelectedLog(matchLog);
		}
	};

	return (
		<>
			<Paper
				component="section"
				sx={{
					"& .logs-row--error": {
						bgcolor: (theme) => (theme.palette.error.light),
					},
					"& .logs-row--error:hover": {
						bgcolor: (theme) => (`${lighten(theme.palette.error.light, 0.1)} !important`),
					},
					"& .logs-row--info": {
						bgcolor: (theme) => (theme.palette.info.light),
					},
					"& .logs-row--info:hover": {
						bgcolor: (theme) => (`${lighten(theme.palette.info.light, 0.1)} !important`),
					},
				}}
			>
				<DataGrid
					columns={columns}
					rows={logs}
					getRowClassName={(params) => (
						params.row.error ? "logs-row--error" : params.row.dir ? "" : "logs-row--info"
					)}
					sx={{
						"& .MuiDataGrid-actionsCell svg": {
							width: "26px",
							height: "26px",
						},
					}}
				/>
			</Paper>
			<Dialog
				id="dlg-log-details"
				fullWidth={true}
				open={selectedLog !== undefined}
				onClose={() => (setSelectedLog(undefined))}
			>
				<DialogTitle>{t("dialog.title.logsTabs")}</DialogTitle>
				<DialogContent>
					<List disablePadding={true}>
						{selectedLog && Object.keys(selectedLog).filter((key) => (key !== "id")).map((key) => (
							<LogDetailItem key={key} label={key} value={selectedLog[key]} />
						))}
					</List>
				</DialogContent>
				<DialogActions>
					{selectedLog?.dir && selectedLog.payload && (selectedLog.payload.requestId || selectedLog.payload.responseId) && !(selectedLog.dir === "TX" && selectedLog.payload.action === "keepAlive") &&
						<Button className="btn-dlg-action-goto" onClick={() => (handleChangeToMatchClick(selectedLog))}>
							{(selectedLog.dir === "TX") ? "Goto RX" : "Goto TX"}
						</Button>
					}
					<Button className="btn-dlg-action-close" color="inherit" onClick={() => (setSelectedLog(undefined))}>{t("dialog.close")}</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default memo(LogsTabsContent);
