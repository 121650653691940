import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Paper, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
// cmp
import GuiJsonEditor from "./custom-editor/gui-json-editor";
import RawJsonEditor from "./custom-editor/raw-json-editor";
// types
import type { TabListProps } from "@mui/lab/TabList";
import type { ValueOf } from "type-fest";

const TAB_IDS = {
	GUI: "gui",
	JSON: "json",
} as const;

type TabId = ValueOf<typeof TAB_IDS>;

type ReviewData = Array<ReviewItem>;
interface ReviewItem {
	label: string;
	oldVal: any;
	newVal: any;
}

type Props = {
	selectedItem: any;
	onDataStringInvalid: () => void;
	onActionJsonUpdate: (json: object, reviewData: ReviewData) => void;
	onTabChange: (tabId: TabId) => void;
};

const TableEditorTabs = ({ selectedItem, onDataStringInvalid, onActionJsonUpdate, onTabChange }: Props) => {
	const { t } = useTranslation();
	const [selectedTab, setSelectedTab] = useState<TabId>(TAB_IDS.GUI);

	const handleTabChange: TabListProps["onChange"] = (event, value: TabId) => {
		setSelectedTab(value);
		onTabChange(value);
	};

	return (
		<TabContext value={selectedTab}>
			<Paper component="section" style={{ marginBottom: "12px" }}>
				<TabList onChange={handleTabChange}>
					<Tab
						id={`tab-table-editor-${TAB_IDS.GUI}`}
						label={t("customtEditor.tabNames.editor")}
						value={TAB_IDS.GUI}
					/>
					<Tab
						id={`tab-table-editor-${TAB_IDS.JSON}`}
						label={t("customtEditor.tabNames.rawJson")}
						value={TAB_IDS.JSON}
					/>
				</TabList>
			</Paper>
			<TabPanel value={TAB_IDS.GUI}>
				<GuiJsonEditor
					selectedItem={selectedItem}
					onActionJsonUpdate={onActionJsonUpdate}
				/>
			</TabPanel>
			<TabPanel value={TAB_IDS.JSON}>
				<RawJsonEditor
					selectedItem={selectedItem}
					onDataStringInvalid={onDataStringInvalid}
					onActionJsonUpdate={onActionJsonUpdate}
				/>
			</TabPanel>
		</TabContext>
	);
};

export default TableEditorTabs;
