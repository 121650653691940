import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Select,
	MenuItem,
	ListItemText,
	FormControl,
	InputLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { MuiFileInput } from "mui-file-input";
// services
import Gupport from "../../services/gupport";
import { icons } from "@local/theme";
// types
import type { CmdUpdateTable } from "../../types/gupport";

type MetadataTableArray = ReadonlyArray<MetadataTable>;
interface MetadataTable {
	id: string;
	desc: string;
}

interface Metadata {
	id: IDWithKey | string;
	data: object;
}

interface IDWithKey {
	string_key: string;
}

type Props = {
	tables: MetadataTableArray;
};

const ImportTable = ({ tables }: Props) => {
	const { t } = useTranslation();

	const [showDialog, setShowDialog] = useState(false);
	const [isUploading, setIsUploading] = useState(false);
	const [selectedTable, setSelectedTable] = useState<string | null>(null);
	const [file, setFile] = useState<File | null>(null);

	const handleClose = () => {
		setShowDialog(false);
		setIsUploading(false);
		setFile(null);
	};

	const handleTableChange = (event) => {
		setIsUploading(false);
		setSelectedTable(event.target.value);
		setFile(null);
	};

	const handleUploadClick = async () => {
		if (file) {
			try {
				setIsUploading(true);

				const text = await file.text();
				const jsonData = JSON.parse(text) as ReadonlyArray<Metadata>;

				const cmd = {
					action: "updateTable",
					tableName: selectedTable,
					data: jsonData.map((data) => ({
						id: (typeof data.id === "string") ? data.id : data.id.string_key,
						data: data.data,
					})),
				} as const satisfies CmdUpdateTable;
				Gupport.send(cmd, (error, msg) => {
					if (!error && msg?.payload.status === "ok") {
						console.info("Imported successfully", msg.payload.data);
						setShowDialog(false);
					} else {
						console.warn("Upload failed", error, msg);
					}
					setIsUploading(false);
				});
			} catch (error) {
				setIsUploading(false);
				console.warn("Failed to read json data", error, file);
			}
		}
	};

	return (
		<>
			<Button
				variant="contained"
				startIcon={<icons.Download />}
				onClick={() => (setShowDialog(true))}
				sx={{ marginLeft: "8px" }}
			>
				{t("teditor.importTable.buttonTitle")}
			</Button>
			<Dialog
				fullWidth={true}
				open={showDialog}
				onClose={handleClose}
			>
				<DialogTitle>{t("teditor.importTable.uploadFile")}</DialogTitle>
				<DialogContent sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
					<FormControl sx={{ marginBottom: "16px" }}>
						<InputLabel shrink={true}>
							{t("teditor.importTable.table")}
						</InputLabel>
						<Select
							fullWidth={true}
							value={selectedTable}
							onChange={handleTableChange}
						>
							{tables.map((table) => (
								<MenuItem key={table.id} value={table.id}>
									<ListItemText primary={table.desc} />
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<MuiFileInput inputProps={{ accept: ".json,application/json" }} value={file} onChange={setFile} />
				</DialogContent>
				<DialogActions>
					<LoadingButton
						disabled={file === null}
						loading={isUploading}
						onClick={handleUploadClick}
						sx={{ marginLeft: "16px" }}
					>
						{t("teditor.importTable.uploadFile")}
					</LoadingButton>
					<Button color="inherit" onClick={handleClose}>{t("dialog.cancel")}</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default ImportTable;
