import { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
	Divider,
	Collapse,
	List,
	ListItemButton,
	ListItemText,
	Avatar,
	Typography,
	ListItemIcon,
	TextField,
	Switch,
	Tooltip,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
// cmp
import RowEntry from "../../../row-entry";
import Svg from "../../../svg";
// services
import { icons } from "@local/theme";

const DeprecatedSection = (props) => {
	const theme = useTheme();
	const { t } = useTranslation();

	const [expanded, setExpanded] = useState(false);

	const hasJsonChanged = (key) => {
		try {
			if (props.selectedItem[key] && props.selectedItem[key] !== null) {
				if (typeof props.selectedItem[key][0] === "string") {
					return props.selectedItem[key][0] !== JSON.stringify(props.previousItem[key] || {}, null, 2);
				}
				return JSON.stringify(props.selectedItem[key][0], null, 2) !== JSON.stringify(props.previousItem[key] || {}, null, 2);
			}
			return false;
		} catch (error) {
			return false;
		}
	};

	return (
		<List disablePadding={true}>
			<ListItemButton
				onClick={() => (setExpanded(!expanded))}
				style={{ paddingTop: "12px", paddingBottom: "12px" }}
			>
				<ListItemText
					primary={
						<Typography variant="body2" style={{ fontWeight: "bold" }}>
							{t("customtEditor.sections.deprecated")}
						</Typography>
					}
				/>
				<Avatar className="count-badge">{props.fieldUpdateCount}</Avatar>
				<ListItemIcon>
					{expanded
						? <icons.ExpandLess htmlColor={theme.palette.text.secondary} />
						: <Svg src="navigation/arrowDropDown.svg" color={theme.palette.text.secondary} />
					}
				</ListItemIcon>
			</ListItemButton>
			<Divider />
			<Collapse in={expanded} timeout="auto">
				<RowEntry title={t("customtEditor.device_details_native.text")} subTitle={t("customtEditor.device_details_native.id")} styleRight={{ margin: "-8px 0", display: "flex", justifyContent: "flex-end" }}>
					{props.previousItem.device_details_native !== props.selectedItem.device_details_native &&
						<ListItemIcon style={{ alignItems: "center" }}>
							<Tooltip title={props.previousItem.device_details_native === undefined ? "NA" : props.previousItem.device_details_native.toString()}>
								<Svg src="actions/editLine.svg" color={theme.palette.primary.main} />
							</Tooltip>
						</ListItemIcon>
					}
					<div style={{ width: "calc(100% - 56px)" }}>
						<Switch
							name="device_details_native"
							checked={props.selectedItem.device_details_native || false}
							onChange={props.onToggleChange}
						/>
					</div>
				</RowEntry>
				<Divider />
				<RowEntry title={t("customtEditor.help.text")} subTitle={t("customtEditor.help.id")} styleRight={{ margin: "-8px 0", display: "flex", justifyContent: "flex-end" }}>
					{props.previousItem.help !== props.selectedItem.help &&
						<ListItemIcon style={{ alignItems: "center" }}>
							<Tooltip title={props.previousItem.help || "NA"}>
								<Svg src="actions/editLine.svg" color={theme.palette.primary.main} />
							</Tooltip>
						</ListItemIcon>
					}
					<TextField
						fullWidth={true}
						name="help"
						placeholder="https://"
						value={props.selectedItem.help || ""}
						onChange={props.fieldUpdate}
						style={{ width: "calc(100% - 56px)" }}
					/>
				</RowEntry>
				<Divider />
				<RowEntry title={t("customtEditor.tags.text")} subTitle={t("customtEditor.tags.id")} styleRight={{ margin: "-8px 0", display: "flex", justifyContent: "flex-end" }}>
					{(hasJsonChanged("tags") && props.tagsStringValid) &&
						<ListItemIcon style={{ alignItems: "center" }}>
							<Tooltip title={
								(props.previousItem.tags && props.previousItem.tags !== null) ?
									((typeof props.previousItem.tags[0] === "string") ?
										props.previousItem.tags[0]
										:
										JSON.stringify(props.previousItem.tags[0], null, 2))
									:
									"NA"
							}
							>
								<Svg src="actions/editLine.svg" color={theme.palette.primary.main} />
							</Tooltip>
						</ListItemIcon>
					}
					<TextField
						name="tags"
						value={
							(props.selectedItem.tags && props.selectedItem.tags !== null) ?
								((typeof props.selectedItem.tags[0] === "string") ?
									props.selectedItem.tags[0]
									:
									JSON.stringify(props.selectedItem.tags[0], null, 2))
								:
								""
						}
						placeholder="{}"
						multiline={true}
						fullWidth={true}
						error={!props.tagsStringValid}
						onChange={props.onActionJsonChange}
						style={{ width: "calc(100% - 56px)" }}
					/>
				</RowEntry>
			</Collapse>
		</List>
	);
};

DeprecatedSection.defaultProps = {
	selectedItem: {
		device_details_native: false,
		help: "",
		tags: null,
	},
};

DeprecatedSection.propTypes = {
	selectedItem: PropTypes.shape({
		device_details_native: PropTypes.bool,
		help: PropTypes.string,
		tags: PropTypes.arrayOf(PropTypes.any), // eslint-disable-line react/forbid-prop-types
	}),
	previousItem: PropTypes.shape({
		device_details_native: PropTypes.bool,
		help: PropTypes.string,
		tags: PropTypes.arrayOf(PropTypes.any), // eslint-disable-line react/forbid-prop-types
	}).isRequired,
	fieldUpdateCount: PropTypes.number.isRequired,
	tagsStringValid: PropTypes.bool.isRequired,
	fieldUpdate: PropTypes.func.isRequired,
	onToggleChange: PropTypes.func.isRequired,
	onActionJsonChange: PropTypes.func.isRequired,
};

export default DeprecatedSection;
