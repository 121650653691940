import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
	Paper,
	Table,
	TableBody,
	TableHead,
	TableCell,
	TableRow,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
// cmp
import Na from "../../na";

const EditableRowInfo = (props) => {
	const theme = useTheme();
	const { t } = useTranslation();

	const columnStyle = {
		paddingRight: "5px",
		paddingLeft: "30px",
		whiteSpace: "normal",
		overflow: "inherit",
		wordBreak: "break-word",
	};

	const headingStyle = {
		paddingRight: "5px",
		paddingLeft: "30px",
	};
	return (
		<Paper className="card-separator">
			<Table>
				<TableHead>
					<TableRow>
						<TableCell style={headingStyle}>{t("teditor.key")}</TableCell>
						<TableCell style={headingStyle}>{t("teditor.name")}</TableCell>
						<TableCell style={headingStyle}>{t("teditor.type")}</TableCell>
						<TableCell style={headingStyle}>{t("teditor.category")}</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<TableRow className="last-row-no-border" style={{ height: "62px" }}>
						<TableCell style={columnStyle} padding="none">{props.selectedItem.id.string_key || <Na />}</TableCell>
						<TableCell style={columnStyle} padding="none">
							<div>{props.selectedItem.data.mfg_name || <Na />}</div>
							<div style={{ color: theme.palette.text.secondary, fontSize: "11px" }}>
								{props.selectedItem.data.mfg_model || ""}
							</div>
						</TableCell>
						<TableCell style={columnStyle} padding="none">
							<div>{t(`rocIds.${props.selectedItem.data.type}`) || <Na />}</div>
							<div style={{ color: theme.palette.text.secondary, fontSize: "11px" }}>
								{props.selectedItem.data.type || ""}
							</div>
						</TableCell>
						<TableCell style={columnStyle} padding="none">
							<div>{t(`deviceCategories.${props.selectedItem.data.category}`) || <Na />}</div>
							<div style={{ color: theme.palette.text.secondary, fontSize: "11px" }}>
								{props.selectedItem.data.category || ""}
							</div>
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</Paper>
	);
};

EditableRowInfo.defaultProps = {
	selectedItem: {
		id: {
			string_key: "",
		},
		data: {
			mfg_name: "",
			mfg_model: "",
			type: "",
			category: "",
		},
	},
};

EditableRowInfo.propTypes = {
	selectedItem: PropTypes.shape({
		id: PropTypes.shape({
			string_key: PropTypes.string,
		}),
		data: PropTypes.shape({
			mfg_name: PropTypes.string,
			mfg_model: PropTypes.string,
			type: PropTypes.string,
			category: PropTypes.string,
		}),
	}),
};

export default EditableRowInfo;
