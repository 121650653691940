import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
	ListItemText,
	TextField,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Select,
	MenuItem,
	Switch,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// cmp
import RowEntry from "../row-entry";
// services
import TemplateJsons from "../../services/template-jsons";
import { sortAlphabetically } from "../../services/l10n";

const AddNewEntryDialog = (props) => {
	const { t } = useTranslation();

	const [adding, setAdding] = useState(false);
	const [value, setValue] = useState(props.newKey);
	const [prefix, setPrefix] = useState(props.rocIdPrefixes.DEFAULT || "");
	const [error, setError] = useState(false);
	const [helperText, setHelperText] = useState("");
	const [templateEnabled, setTemplateEnabled] = useState(false);
	const [templateType, setTemplateType] = useState("");

	const getHelperText = (error) => {
		if (error) {
			return props.isRocIdDict ? t("teditor.keyErrorText") : t("teditor.duplicateKeyError");
		} else {
			return props.isRocIdDict ? t("teditor.keyValidText") : "";
		}
	};

	useEffect(() => {
		if (props.isDuplicate && props.isRocIdDict) {
			const prefix = Object.keys(props.rocIdPrefixes).find((prefix) => (props.newKey.startsWith(prefix)));
			if (prefix) {
				setPrefix(props.rocIdPrefixes[prefix]);
			} else if (!Number.isNaN(Number(props.newKey))) {
				setPrefix(props.rocIdPrefixes.DEFAULT);
			}
		}
		setValue(props.newKey);
		setError(props.checkForExistingKeys(props.newKey));
		setHelperText(getHelperText(props.checkForExistingKeys(props.newKey)));
	}, [props.newKey]);

	const handleNewKeyChange = (event) => {
		const text = event.target.value;
		if (props.newKeyType === "object") {
			try {
				setError(props.checkForExistingKeys(text));
				setHelperText(getHelperText(props.checkForExistingKeys(text)));
			} catch (error) {
				setError(true);
				setHelperText(t("teditor.invalidKeyText"));
			}
		} else {
			setError(props.checkForExistingKeys(text));
			setHelperText(getHelperText(props.checkForExistingKeys(text)));
		}
		setValue(text);
	};

	const handlePrefixChange = (event) => {
		const text = event.target.value;
		setPrefix(text);
		setValue(text);
		setError(props.checkForExistingKeys(text));
		setHelperText(getHelperText(props.checkForExistingKeys(text)));
	};

	const handleToggleChange = (event) => {
		setTemplateEnabled(event.target.checked);
		setTemplateType("");
	};

	const handleTemplateChange = (event) => {
		setTemplateType(event.target.value);
	};

	const getTemplateJson = () => {
		let templateJson = TemplateJsons[templateType] || null;
		if (templateJson && templateJson.rocid === "{{ROCID}}") {
			templateJson.rocid = value;
		} else if (props.isRocIdDict && templateJson === null) {
			templateJson = { rocid: value };
		}
		return templateJson;
	};

	const handleAddClick = () => {
		setAdding(true);
		props.onAddClick(value, getTemplateJson());
	};

	const templateList = Object.keys(TemplateJsons).sort(sortAlphabetically);

	return (
		<Dialog
			fullWidth={true}
			open={true}
			onClose={props.onCloseHandler}
		>
			<DialogTitle>{t("teditor.chooseKey")}</DialogTitle>
			<DialogContent>
				{props.isRocIdDict &&
					<RowEntry title={t("teditor.key")} titleWidth={40} styleRight={{ margin: "-8px 0" }}>
						<Select
							fullWidth={true}
							value={prefix}
							onChange={handlePrefixChange}
						>
							{
								Object.keys(props.rocIdPrefixes).map((key) => (
									<MenuItem key={key} name={key} value={props.rocIdPrefixes[key]}>
										<ListItemText primary={key} />
									</MenuItem>
								))
							}
						</Select>
					</RowEntry>
				}
				<RowEntry title={props.isRocIdDict ? t("teditor.rocId") : t("teditor.key")} titleWidth={40} styleRight={{ margin: "-8px 0" }}>
					<TextField
						placeholder="0000"
						fullWidth={true}
						multiline={true}
						InputLabelProps={{ shrink: true }}
						value={value}
						error={error}
						helperText={helperText}
						onChange={handleNewKeyChange}
					/>
				</RowEntry>
				{(!props.isDuplicate && props.isRocIdDict) &&
					<RowEntry title={t("teditor.chooseTemplate")} titleWidth={40} styleRight={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "-8px 0" }}>
						<Switch
							checked={templateEnabled}
							onChange={handleToggleChange}
						/>
						<Select
							fullWidth={true}
							value={templateType}
							disabled={!templateEnabled}
							onChange={handleTemplateChange}
						>
							{
								templateList.map((key) => (
									<MenuItem key={key} value={key}>
										<ListItemText primary={key} />
									</MenuItem>
								))
							}
						</Select>
					</RowEntry>
				}
			</DialogContent>
			<DialogActions>
				<LoadingButton
					disabled={((templateEnabled && templateType === "") || value === "" || error)}
					loading={adding}
					onClick={handleAddClick}
				>
					{t("dialog.add")}
				</LoadingButton>
				<Button color="inherit" onClick={props.onCloseHandler}>{t("dialog.cancel")}</Button>
			</DialogActions>
		</Dialog>
	);
};

AddNewEntryDialog.defaultProps = {
	rocIdPrefixes: {},
};

AddNewEntryDialog.propTypes = {
	newKey: PropTypes.string.isRequired,
	newKeyType: PropTypes.string.isRequired,
	rocIdPrefixes: PropTypes.object,
	isDuplicate: PropTypes.bool.isRequired,
	isRocIdDict: PropTypes.bool.isRequired,
	onAddClick: PropTypes.func.isRequired,
	checkForExistingKeys: PropTypes.func.isRequired,
	onCloseHandler: PropTypes.func.isRequired,
};

export default AddNewEntryDialog;
