import { Component } from "react";
import PropTypes from "prop-types";
import {
	Divider,
	List,
	ListItemButton,
	ListItemText,
	ListSubheader,
} from "@mui/material";
// services
import Constants from "../../services/constants";

class ActionBuilder extends Component {

	constructor(props) {
		super(props);

		this.state = {
			selectedSchema: null,
			actionJson: "{\n}",
		};
	}

	handleActionChange(value) {
		const schemaChanged = this.props.schemas.find((schema) => (schema.id.string_key === value));
		const json = JSON.stringify(schemaChanged.data.payload, null, 2);

		this.setState({
			selectedSchema: value,
			actionJson: json,
		}, () => {
			if (this.props.onChange) {
				this.props.onChange(this.state.selectedSchema, this.state.actionJson);
			}
		});
	}

	renderActionItems() {
		const actionList = [];

		this.props.rbacs.forEach((rbac, index) => {
			if (index > 0) {
				actionList.push(<Divider key={`${rbac.name} -divider`} />);
			}
			actionList.push(<ListSubheader disableSticky={true} key={`${rbac.name}-header`}>{rbac.name}</ListSubheader>);

			rbac.apis.filter((schema) => (
				schema.data.dir === "TX" && (schema.data.api === Constants.WsType.Glient || schema.data.api === Constants.WsType.Gupport)
			)).forEach((schema) => {
				actionList.push(
					<ListItemButton
						key={schema.id.string_key}
						onClick={this.handleActionChange.bind(this, schema.id.string_key)}
					>
						<ListItemText primary={schema.data.label} secondary={schema.data.description} />
					</ListItemButton>
				);
			});
		});

		return actionList;
	}

	render() {
		return (
			<List>
				{this.renderActionItems()}
			</List>
		);
	}

}

ActionBuilder.defaultProps = {
	schemas: null,
	onChange: null,
};

ActionBuilder.propTypes = {
	schemas: PropTypes.arrayOf(PropTypes.object),
	rbacs: PropTypes.arrayOf(PropTypes.object).isRequired,
	onChange: PropTypes.func,
};

export default ActionBuilder;
