import { Component, Children } from "react";
import PropTypes from "prop-types";
// services
import Responsive from "../services/responsive";
// types
import type { ReactNode } from "react";

type Props = {
	children: ReactNode;
};

type State = {
	screens: Array<any>; // TODO
};

class ResponsiveLayout extends Component<Props, State> {

	constructor(props: Props) {
		super(props);

		this.state = {
			screens: Responsive.getScreenSizes(),
		};

		this.handleScreenChanged = this.handleScreenChanged.bind(this);
	}

	override componentDidMount() {
		Responsive.on("screenChanged", this.handleScreenChanged);
	}

	override componentWillUnmount() {
		Responsive.off("screenChanged", this.handleScreenChanged);
	}

	handleScreenChanged() {
		this.setState({
			screens: Responsive.getScreenSizes(),
		});
	}

	override render() {
		const children = Children.toArray(this.props.children);

		for (const screen of this.state.screens) {
			const match = children.find((child) => (child.props.screen === screen));
			if (match) {
				return match;
			}
		}

		return null;
	}

}

ResponsiveLayout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default ResponsiveLayout;
